/* CSS Custom Properties Definitions */
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");

:root {
  --color-primary: #005b99;
  --color-secondary: #005b99;
  --color-accent: #005b99;
  --color-text: #2e353f;
  --color-text-light: #4f5969;
  --color-heading: #1a202c;
  --color-heading-black: black;
}

/* HTML elements */

/* body font */
@font-face {
  font-family: "FreightTextProMedium";
  src: url("./assets/fonts/FreightTextPro/FreightTextProMedium-Regular.eot");
  src: local("FreightText Pro Medium"), local("FreightTextProMedium-Regular"),
    url("./assets/fonts/FreightTextPro/FreightTextProMedium-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/FreightTextPro/FreightTextProMedium-Regular.woff2") format("woff2"),
    url("./assets/fonts/FreightTextPro/FreightTextProMedium-Regular.woff") format("woff"),
    url("./assets/fonts/FreightTextPro/FreightTextProMedium-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  font-family: "FreightTextProMedium";
  line-height: 1.5;
  font-weight: 500;
  color: var(--color-text);
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: var(--color-text);
}

h1,
.h1 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 31px;
}

.inner-page h1 {
  font-size: 25px;
}

h2,
.h2 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 25px;
}

h3,
.h3 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 400;
}

.display-linebreak {
  white-space: pre-line;
}

.section-mb {
  margin-bottom: 48px;
}

/* responsive container */
.container {
  margin-right: auto;
  margin-left: auto;
}

.sliderinner {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  padding-bottom: 47%;
}

/* header */
.navbar {
  display: block;
  padding: 25px 0;
  text-align: center;
}

.navbar__logo {
  flex: 1;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.navbar__menu {
  display: none;
}

.hamburger {
  display: block;
  background-color: #2e3945;
}

.hamburger .line {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px auto;
}

/* Footer */

footer {
  color: #fff;
  background-color: #2e3945;
  padding: 50px 30px;
}

.footer-container {
  display: block;
}

.footer--right {
  margin-top: 50px;
  display: inline-grid;
}

.footer-image {
  width: 150px;
  margin-bottom: 50px;
}

.navbar__logo h2 {
  font-size: 21px;
}

.navbar__menu a:last-child {
  padding-right: 0px !important;
}

/* Nav menu */
.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(46, 57, 69, 0.93);
  overflow: hidden;
}

.menu a {
  display: block;
  padding: 20px;
  margin: 0 10px;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.menu a:last-child {
  border-bottom: none;
}

.menu a:hover {
  background-color: #2e3945;
}

.nav {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  z-index: 100;
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
}

.hamb {
  cursor: pointer;
  padding: 22px 20px;
  display: block;
}

/* Style label tag */

.hamb-line {
  background: #fff;
  display: block;
  height: 4px;
  position: relative;
  width: 28px;
  margin: auto;
}

/* Style span tag */

.hamb-line::before,
.hamb-line::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.hamb-line::before {
  top: 10px;
}

.hamb-line::after {
  top: -10px;
}

.side-menu {
  display: none;
  position: relative;
  left: 50%;
  top: 50%;
}

/* Hide checkbox */
/* Toggle menu icon */
.side-menu:checked~nav {
  max-height: 100%;
}

.side-menu:checked~.hamb .hamb-line {
  background: transparent;
}

.side-menu:checked~.hamb .hamb-line::before {
  transform: rotate(-45deg);
  top: 0;
}

.side-menu:checked~.hamb .hamb-line::after {
  transform: rotate(45deg);
  top: 0;
}

/* Intro text */
.intro-text p {
  font-size: 25px;
  text-align: center;
  width: 85%;
  margin: 0 auto;
}

.text-section p {
  font-size: 20px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

.title-center {
  padding-bottom: 16px;
  text-align: center;
}

/* Selected projects */
.selected-project-item .description h3 {
  margin-top: 8px;
}

.selected-project-item .description p {
  text-transform: capitalize;
  font-size: 21px;
}

/* Single project page  */
.single-project header {
  margin-bottom: 24px;
  text-align: center;
}

.single-project h1 {}

.single-project header>p {
  text-transform: capitalize;
}

.side-by-side-images>.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.side-by-side-images img {
  /* height: 100%; */
}

.side-by-side-images span.lazy-load-image-loaded {
  margin-right: 16px;
}

.side-by-side-images span.lazy-load-image-loaded:last-child {
  margin-right: 0px;
}

.full-width-image span.lazy-load-image-loaded {
  width: 100%;
}

.full-width-image img {
  width: 100%;
}

.single-image>.container {
  text-align: center;
}

.single-image img {
  width: 100%;
}

.project-navigation>.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}

.previous-arrow {
  margin-right: 16px;
  margin-top: 4px;
}

.next-arrow {
  margin-left: 16px;
  margin-top: 4px;
}

.masonry-container {
  margin: 0 16px;
}

@media screen and (max-width: 768px) {}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

/* navigation underline */

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 80px;
  transform: scaleX(0);
  height: 2px;
  bottom: 5px;
  left: 0;
  background-color: var(--color-text);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation.active:after {
  width: 0;
  transform: none;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-animation.active::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 2px;
  bottom: 5px;
  left: 0;
  background-color: var(--color-text);
}

.project-navigation .h1 {
  display: none;
}

.navbar {
  text-align: center;
  display: flex;
  padding: 50px 30px;
}

/* studio */
.mb-50 {
  margin-bottom: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.pb-50 {
  padding: 50px 0;
}

.text-section-studio {
  font-size: 20px;
  line-height: 26px;
}

.text-section-studio p {
  margin-bottom: 25px;
}

.columnleft {
  float: left;
  width: 100%;
  padding: 0 20px;
}

.columnright {
  float: left;
  width: 100%;
  padding: 0 20px;
}

.columnright img {
  max-width: 250px;
}

.column-50 {
  float: left;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 25px;
}

.awards .container:after,
.text-section-studio .container:after {
  content: "";
  display: table;
  clear: both;
}

.awards {
  background-color: #f5f5f5;
  color: #505050;
}

.award-container {
  font-size: 20px;
}

.award-container::not(.project) {
  margin-bottom: 20px;
}

.award-container p {
  margin-bottom: 5px;
}

.award-container h3 {
  color: #505050;
  font-size: 20px;
  margin-bottom: 5px;
}

.award-content {
  font-style: italic;
}

/* contact */
.contacts-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 0 30px;
}

.contact-item {
  flex-grow: 1;
  margin-bottom: 16px;
}

.contact-item h2 {
  font-size: 20px;
  margin-bottom: 8px;
}

/* 404 */
.not-found-container {
  margin-bottom: 48px;
  padding: 0 24px;
  text-align: center;
}

.not-found-container p,
.not-found-container a {
  font-size: 21px;
  margin-top: 24px;
}

.not-found-container a {
  color: darkblue;
}

.link-container {
  margin-top: 24px;
}

.publications .outer {
  margin: 0px 8px;
}

/* publications */

.publication-container {
  display: grid;
  grid-template-rows: auto auto;
  column-gap: 24px;
  margin: 16px 0;
}

.publication-title {
  font-size: 21px;
}

.publication-type {
  text-transform: uppercase;
  font-family: "Lato";
}

.publication-description {
  margin-top: 8px;
}

.publication-btn {
  background-color: #f6cf46;
  font-family: "Lato";
  border: none;
  padding: 4px 16px;
  text-transform: uppercase;
  float: left;
  margin-top: 8px;
}

.publication-container hr {
  height: 0.5px;
  background-color: #b8babe;
  border: none;
}

.awards-container {
  padding: 0 20px;
}

.award-item {
  margin-bottom: 25px;
}

.award-item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .navbar__menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    visibility: visible;
  }

  .navbar {
    text-align: left;
  }

  .hamburger {
    display: none;
  }

  .navbar__menu a {
    min-height: 37.5px;
    font-size: 20px;
    padding-right: 25px;
  }

  footer {
    padding: 100px 30px;
  }

  .project-navigation {
    margin-left: 50px;
    margin-right: 50px;
  }

  .project-navigation a {
    display: flex;
    align-items: flex-end;
  }

  .project-navigation>.container {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
  }

  .footer--right {
    display: flex;
    flex-direction: column;
    /* margin-right: 50px; */
    margin-top: 0;
  }

  .project-navigation .h1 {
    display: inline;
    font-size: 23px;
  }

  .column-50 {
    width: 50%;
    padding: 0 30px;
  }

  .columnleft {
    width: 70%;
    padding: 0 30px;
  }

  .columnright {
    width: 30%;
    padding: 0 30px;
  }

  .columnright img {
    max-width: none;
  }

  .contacts-container {
    flex-direction: row;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  .publication-container {
    grid-template-columns: auto auto;
  }

  .publication-btn {
    float: right;
  }

  .awards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px 8px;
  }

  .award-item {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 992px) {
  .container {
    padding: 0 30px;
  }

  .navbar {
    padding: 50px 0;
  }

  .navbar__menu a {
    padding-right: 40px;
  }

  .navbar__logo h2 {
    font-size: 25px;
  }

  .masonry-container {
    margin: 0;
  }

  .columnleft {
    padding: 0 30px 0 0;
  }

  .columnright {
    padding: 0 0 0 10px;
  }

  .column-50 {
    padding: 0 10px 0 0;
  }

  footer {
    padding: 100px 0;
  }

  .publications .outer {
    margin: 0px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1140px;
  }

  .navbar__menu a {
    padding-right: 70px;
  }

  .navbar {
    text-align: left;
    display: flex;
    padding: 50px 0;
  }

  .sliderinner {
    margin: 0;
  }
}

@media screen and (min-width: 1300px) {
  .container {
    width: 1240px;
  }
}

div#preload {
  display: none;
}

.sliderinner .active {
  animation-delay: 0.5s;
  -webkit-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear forwards;
}

@-webkit-keyframes fadeinout {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.nav__section .fade-in-section {
  transform: translateY(0vh);
}

.page-title {
  animation: fadeInTitle ease-out 0.5s;
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}